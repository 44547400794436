//bogart color theme:
$bogart-cream: #dddcc5;
$bogart-brown: #958976;
$bogart-red: #611427;
$bogart-dark: #1d2326;
$bogart-grey: #6a6a61;
$bogart-gray: #6a6a61; //synonym

@import "settings";
@import "fnd/foundation";


////////////////////////////////////////

// common definitions

$line-color: $bogart-cream;

// typography

hr {
   border-color: $line-color;
}

a {
    text-decoration: underline;
}

// a:visited {
//   color: $bogart-grey;//$secondary-color;
//}

code {
     font-size: 0.9rem;
}

pre code {
    font-size: 1rem;
    display: block;
}

h1, h2, h3, p {
    margin: 1.5rem auto;
}

h1 {
   text-align: center;
   //line-height: 1.5em;
}

h2 {
   line-height: 1em;
   font-size: 1.5rem !important;
}

h3 {
   font-size: 1.17rem;
   line-height: 1.5rem;
   font-weight: bold;
}

dd {
   margin-left: 2rem;
}

ul.article-list {
  li {
    margin-bottom: 0.75rem;
  }
}

// hanging titles

@media #{$large-up} {
    dl.hanging dt {
        width: 34rem;
        margin: 0;
        margin-left: -34rem;
        float: left;
        text-align: right;
        padding-right: 1rem;
        font-style: italic;
        font-weight: normal;
    }
}

dl.hanging {
    dt {
        font-style: italic;
        font-weight: normal;
    }

    dd {
        margin: 0;
        margin-bottom: 1.5rem;
    }
}

// blocks

.block {
    padding: calc(1.5rem - 1px) 2rem;
    border-top: 1px solid $line-color;
    border-bottom: 1px solid $line-color;
}

blockquote {
    font-style: italic;
    @extend .block;
    border-left: none;

    cite {
        text-align: right;
        font-style: normal;
        font-variant: small-caps;
        margin: 0;
    }

    cite:before {
        content: "– ";
    }
}

blockquote.display {
    font-size: 1.25rem;
    line-height:1.5rem;

    cite {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
}

// warning block

.warning {
    @extend .block;
}

.warning:before {
    content: "Warning: ";
    font-weight: bold;
}

// note block

.note {
    @extend .block;
}

.note:before {
    content: "Note: ";
    font-weight: bold;
}

// layout

.sidebar {
  //position: relative;
  margin: 0;
  width: 100%;
  background-color: $bogart-dark;
  min-height: 100%;
}

.layout {
  padding-left: 0;
}

.main {
//  background: url("grid2.png");
  width: 100%;
  padding: 0;
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: 35em;
  min-height: 100%;
  text-align: justify;
}


@media #{$medium-up}{
  .layout {
    padding-left: 12em;
  }

  .sidebar {
    width: 12em;
    margin-left: -12em;
    position: fixed;
    left: 12em;
  }

  .main {
    padding: 1.5rem 1rem 0 1rem;
    //background: url("/img/grid.png");
  }

  .content {
    width: 100%;
  }
}

// sidebar

.navigation {
  a {
    text-decoration:none;
    padding: 0 1.5em;
    display: block;
    line-height: 2em;
  }
}

a.home-link {
  color: $bogart-cream;
  background-color: $bogart-red;
  text-align: center;
  line-height: 2.5rem;
  letter-spacing: 0.2em;
  font-variant: small-caps;
  margin-bottom: 2rem;
}

a.home-link:hover {
    text-decoration: underline;
}

nav.small-header {
  background-color: $bogart-red;
  color: $bogart-cream;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 0.5rem;
  height: 3.5rem;
  margin-bottom: 1rem;
  text-decoration: underline;
  font-variant: small-caps;
  //text-transform: uppercase;
  letter-spacing: 0.2em;
  
  a {
    color: $bogart-cream;
  }

  a.menu-icon {
    display: block;
    text-indent: 999rem;

    border-top: 1px solid $bogart-cream;
    border-bottom: 1px solid $bogart-cream;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.5rem;
    margin-left: -1.5rem;
  }

  a.menu-icon:before {
    content: "";
    margin-top: calc(0.75rem - 1px);
    display: block;
    width:100%;
    border-top: 1px solid $bogart-cream;
  }
}

.side-nav-inner {
  @include side-nav($font-size:1em);
  
  li {
    a:not(.button) {
      padding: 0 2rem;
    }
  }
}

@media #{$medium-up} {
  nav.navigation {
    padding-bottom: 15rem; // height of the footer
  }
}

// footer

.stretch {
    margin-top: 0;
    width:100%;
    margin-bottom: 0;
    background-color:red;
}

.side-footer {
    margin-top: 0;
    padding: 1em;
    color: $bogart-cream;
    position: absolute;
    bottom: 1rem; // TODO
    height:13em;
}

.bottom-footer {
    padding: 1em;
    color: $bogart-cream;
    background-color: $bogart-dark;
    text-align: center;
    margin-top: 2em;
    width: 100%;
}

//imprint

.imprint {
  font-size: 0.8rem;
  font-variant: small-caps;
  letter-spacing: 0.2em;
  text-align: center;
  margin: 0;

  a {
    color: $bogart-cream;
    text-decoration: none;
  }

  a:hover, a:focus {
    text-decoration: underline;
  }
}
